import styled from 'styled-components';
import Markdown from 'react-markdown';
//import JSXMarkdown from 'markdown-to-jsx';
//import Image from 'next/image';
import React from 'react';
import { GetStaticProps, NextPage } from 'next';

const Advatnage = styled.div`
  display: grid;

  /*
  justify-items and justify-self align items 
  along the row axis, and align-items and align-self 
  align items along the column axis.

  justify-items and align-items 
  are applied to the grid container and support 
  the following values:
  */
  //flex-direction: column;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  //color: blueviolet;

  gap: 20px;
`;
const SvgIcon = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 0;
  grid-row-end: 1;
  @media screen and (max-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 0;
    grid-row-end: 1;
  }
  display: flex;
  align-items: center;
  justify-items: center;
`;
const ImageWrapper = styled.div`
  background: rgba(145, 124, 147, 0.1);
  border-radius: 50%;
  padding: 12px 14px;
  //padding: 2.67px 4.66px 2.66px 4.67px;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const Name = styled.div`
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 0;
  grid-row-end: 1;
  max-width: 250px;
  @media screen and (min-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  /* identical to box height, or 36px */

  color: #292838;
`;
const Description = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 160%;

  max-width: 250px;

  color: #292838;
  @media screen and (min-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`;

const MarkdownW = styled(Markdown)``;

type AdvantageType = {
  iconUrl?: string;
  className?: string;
  blogCategory?: string;
  name?: string;
  description?: string;
};

const AdvantageComponent: NextPage<AdvantageType> = ({
  className,
  iconUrl,
  name,
  description,
}): JSX.Element => {
  const markdown = `
* Кловский спуск, 8
* Оболонский проспект, 1
* Бульвар Кольцова, 14ж 
`;

  /*
width: 22.67px;
height: 26.67px;
*/
  return (
    <Advatnage className={className}>
      <SvgIcon>
        <ImageWrapper>
          <img
            src={iconUrl ? iconUrl : '/assets/icons/LocationT.svg'}
            width="22.67px"
            height="26.67px"
            alt="icon"
          ></img>
        </ImageWrapper>
      </SvgIcon>
      <Name>{name}</Name>
      <Description>
        <MarkdownW>{description}</MarkdownW>
        {/*<JSXMarkdown>{description}</JSXMarkdown>*/}
      </Description>
    </Advatnage>
  );
};
//      <ReactMarkdown># Hello, *world*!</ReactMarkdown>

export default AdvantageComponent;

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      blogCategory: 'ReactJS',
    },
  };
};
