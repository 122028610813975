import { NextPage } from 'next';
import React from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';

export type ImportantDataT = {
  id: number;
  name: string;
  icon: string;
  description: string;
};

type ImportantComponetI = {
  className?: string;
  data?: ImportantDataT[];
};
const SvgIcon = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 0;
  grid-row-end: 1;
  display: flex;
  align-items: center;
  justify-items: center;
`;
const ImageWrapper = styled.div`
  background: rgba(145, 124, 147, 0.1);
  border-radius: 50%;
  padding: 6px 9px;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const Name = styled.div`
  /* Desktop/Heading/H3 */
  margin-left: 5px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  color: #292838;

  //max-width: 250px;
  grid-column-start: 2;
  grid-column-end: auto;
  grid-row-start: 0;
  grid-row-end: 1;

  /* identical to box height, or 36px */

  color: #292838;
`;

const Description = styled.div`
  grid-column-start: 2;
  grid-column-end: 18;
  grid-row-start: 2;
  grid-row-end: 3;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  max-width: 750px;

  color: #292838;
`;

const MarkdownW = styled(Markdown)`
  & > ul {
    padding-inline-start: 20px;
  }
  & > ul > li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & > ul > li::marker {
    color: #917c93;
  }
`;

const ImportantComponentWrapper = styled.div`
  // display: flex;
`;
const ImportantElementWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px repeat(18, auto);
  grid-template-rows: repeat(3, auto);
  align-items: center;
  gap: 5px;
  //justify-items: left;
  justify-items: stretch;
  //color: blueviolet;
  //gap: 20px;
`;

export const ImportantData: ImportantDataT[] = [
  {
    id: 1,
    name: 'Важно!',
    icon: '/assets/icons/NotificationT.svg',
    description: `* Абонемент действует в течение месяца (30 календарных дней)

* Пропущенные занятия можно отработать на других занятиях и в других локациях нашей студии

* Количество людей в группах до 8 человек

* Заморозить абонемент можно в случае болезни при наличии медицинской справки или договорённости во время покупки абонемента

* Запись на занятия обязательна! При отмене занятия менее, чем за 3 часа, занятие списывается. 
`,
  },
];

const ImportantComponent: NextPage<ImportantComponetI> = ({ className }) => {
  return (
    <ImportantComponentWrapper className={className}>
      {ImportantData.map((c) => (
        <ImportantElementWrapper key={c.id}>
          <SvgIcon>
            <ImageWrapper>
              <img
                //layout="fixed"
                src={c.icon ? c.icon : '/assets/icons/LocationT.svg'}
                width={22.86}
                height={26.86}
                alt="icon"
              ></img>
            </ImageWrapper>
          </SvgIcon>
          <Name>{c.name}</Name>
          <Description>
            <MarkdownW>{c.description}</MarkdownW>
          </Description>
        </ImportantElementWrapper>
      ))}
    </ImportantComponentWrapper>
  );
};

export default ImportantComponent;
