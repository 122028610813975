import { NextPage } from 'next';
import styled from 'styled-components';

const SuccessHeader = styled.div`
  /* Desktop/Heading/H3 */

  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Light mode/Neutral/dark */

  color: #292838;
`;

const TeacherWillContactYou = styled.div`
  /* Desktop/Body/Medium */

  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Light mode/Neutral/dark */

  color: #292838;
`;
type ModalT = {
  active?: boolean;
  setActive?(active: boolean): void;
};
const ModalComponentWrapper = styled.div<ModalT>`
  height: 104vh;
  width: 104vw;

  display: flex;

  top: 0;
  left: 0;
  z-index: 30;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  //transform: scale(0);
  transform: scale(${(props) => (props.active ? 1 : 0)});
`;

const ModalWindow = styled.div`
  padding: 20px 20px 40px 20px;
  display: flex;
  width: 80vw;

  border-radius: 20px;
  background: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Modal: NextPage<ModalT> = ({ active, setActive }) => {
  return (
    <ModalComponentWrapper active={active}>
      <ModalWindow onClick={() => setActive(false)}>
        <SuccessHeader>Твоя заявка успешно отправлена</SuccessHeader>
        <TeacherWillContactYou>
          Преподаватель с тобой скоро свяжется
        </TeacherWillContactYou>
      </ModalWindow>
    </ModalComponentWrapper>
  );
};

export default Modal;
