import { NextApiRequest, NextApiResponse } from 'next';

export interface AdvatageDataI {
  id: number;
  name: string;
  icon: string;
  description: string;
}
export const AdvatageData: AdvatageDataI[] = [
  {
    id: 1,
    name: '3 удобных локации',
    icon: '/assets/icons/LocationT.svg',
    description: `* Кловский спуск, 8
* Оболонский проспект, 1
* Бульвар Кольцова, 14ж 
`,
  },
  {
    id: 2,
    name: 'Дипломированные педагоги',
    icon: '/assets/icons/StarT.svg',
    description: `Дипломированные педагоги со знанием анатомии и реабилитологии.`,
  },
  {
    id: 3,
    name: 'Богатый опыт',
    icon: '/assets/icons/WorksuitcaseT.svg',
    description: `Более 5 лет опыта работы и более 500 довольных клиентов.`,
  },
  {
    id: 4,
    name: 'Гибкое расписание',
    icon: '/assets/icons/CalendarT.svg',
    description: `Занятия с Пн по Сб в группах и индивидуально, чтобы ты могла выбрать лучшее время для себя.`,
  },
  {
    id: 5,
    name: 'Мини-группы',
    icon: '/assets/icons/UsergroupT.svg',
    description: `Мы набираем не больше 8 человек в группы, чтобы каждый ученик получил внимание педагога`,
  },
];

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse,
): void {
  res.status(200).json(AdvatageData);
}
