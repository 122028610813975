import styled from 'styled-components';
import Advatage from 'components/molecules/Advantage';
import { NextPage } from 'next';
//import { GetStaticProps, InferGetStaticPropsType } from 'next';
//import { AdvatageData } from 'data/index';
export const TextBeforeHeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export type AdvatageDataI = {
  id: number;
  name: string;
  icon: string;
  description: string;
};

export const Choose = styled.div``;

export const Advatages = styled.div`
  //margin: 0px 5% 0px 5%;
  //width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;

  //gap: 20px;

  //grid-template-columns: auto auto auto;
  //grid-template-rows: auto auto auto;
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: stretch;
    margin: 0px 0% 0px 0%;
    //grid-template-columns: auto;
    //grid-template-rows: auto auto auto;
  }
  //flex-wrap: wrap;
  //flex-direction: row;
  //justify-content: center;
`;

export const AdwantageWrapper = styled(Advatage)`
  //flex: 1 0 33%; /* explanation below */
  //flex: 1 0 25%;
  margin: 10px;

  //min-width: 250px;
`;

export const TextBeforeHead = styled.div`
  /* Desktop/Body/Xlarge */
  margin-top: 3em;
  text-align: center;
  max-width: 800px;

  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 150%;
  /* or 45px */

  /* Neutral/black */

  color: #292838;
`;
export const TextHeadWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 28px;
  display: flex;
  justify-content: center;
`;

export const TextHead = styled.div`
  //width: 617px;
  //height: 58px;
  //left: 411px;
  //top: 1295px;

  /* Desktop/Heading/H2 */

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #292838;
`;
type ChooseComponentType = {
  advantages: AdvatageDataI[];
};

const ChooseComponent: NextPage<ChooseComponentType> = ({
  advantages,
}): JSX.Element => {
  //console.log(advantages);
  return (
    <Choose>
      <TextBeforeHeadWrapper>
        <TextBeforeHead>
          Мы научим тебя не только танцевать и садиться на шпагат, но и поможем
          приобрести хорошую осанку, расслабить и укрепить мышцы, полюбить своё
          тело. А ещё ты полюбишь себя и приобретёшь уверенность!
        </TextBeforeHead>
      </TextBeforeHeadWrapper>
      <TextHeadWrapper>
        <TextHead>Почему выбирают нас</TextHead>
      </TextHeadWrapper>
      <Advatages>
        {advantages.map((c) => (
          <AdwantageWrapper
            key={c.id}
            name={c.name}
            description={c.description}
            iconUrl={c.icon}
          />
        ))}
      </Advatages>
    </Choose>
  );
};

export default ChooseComponent;
