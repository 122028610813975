import { NextPage } from 'next';
import styled from 'styled-components';
import Icon from 'components/atoms/icon';

// For styling any nested component, Overlay use the styled method.
// This method works perfectly on all of your any third-party component, as long as they attach the passed className prop to a DOM element.
// Here an example: https://gist.github.com/Miniplop/80b042d1b44293329ef7332fd47b770c

// Nested component imports
//import { IconlyLightOutlineTimeCircle } from 'components';

export const Slot = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto auto;
  flex-direction: column;
  align-items: flex-start;
`;
export const DirectionType = styled.p`
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
  width: 173.59px;
  //color: ${(props) => props.theme.colors.darkSlateGray};
  margin-bottom: 8px;
  /*font-family: ${(props) => props.theme.fonts.desktopHeadingH4.family};
  font-size: ${(props) => props.theme.fonts.desktopHeadingH4.size};
  font-weight: ${(props) => props.theme.fonts.desktopHeadingH4.weight};
  line-height: ${(props) => props.theme.fonts.desktopHeadingH4.lineHeight};
  */
`;

export const TimeRange = styled.p`
  /*
  color: ${(props) => props.theme.colors.darkSlateGray};
  font-family: ${(props) => props.theme.fonts.desktopBodySmall.family};
  font-size: ${(props) => props.theme.fonts.desktopBodySmall.size};
  font-weight: ${(props) => props.theme.fonts.desktopBodySmall.weight};
  line-height: ${(props) => props.theme.fonts.desktopBodySmall.lineHeight};
  */
`;
export const Location = styled.p`
  /*
  color: ${(props) => props.theme.colors.darkSlateGray};
  font-family: ${(props) => props.theme.fonts.desktopBodySmall.family};
  font-size: ${(props) => props.theme.fonts.desktopBodySmall.size};
  font-weight: ${(props) => props.theme.fonts.desktopBodySmall.weight};
  line-height: ${(props) => props.theme.fonts.desktopBodySmall.lineHeight};
  */
`;

const TimeIcon = styled(Icon)`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
`;
const LocationIcon = styled(Icon)`
  grid-column: 1 / 2;
  grid-row: 3 / 4;
`;

type SlotT = {
  className?: string;
  id: number;
  dayId: number[];
  startTime: string;
  endTime: string;
  type: string;
  location: string[];
  timeIconUrl?: string;
  locationIconUrl?: string;
};

const SlotComponent: NextPage<SlotT> = ({
  className,
  type,
  startTime,
  endTime,
  location,
  locationIconUrl,
}) => {
  return (
    <Slot className={className}>
      <DirectionType>{type}</DirectionType>
      <TimeIcon src="/assets/icons/TimeCircle.svg" height="20px" width="20px" />
      <TimeRange>
        {startTime} - {endTime}
      </TimeRange>
      <LocationIcon src={locationIconUrl} height="20px" width="20px" />
      <Location>{location}</Location>
    </Slot>
  );
};

export default SlotComponent;
