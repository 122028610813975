import styled from 'styled-components';
import Direction, { DirectionDataT } from 'components/molecules/Direction';
import DirectionType, {
  DirectionTypeDataT,
} from 'components/molecules/DirectionType';
import { NextPage } from 'next';
import React from 'react';
import Link from 'next/link';

const Header = styled.div`
  /* Desktop/Heading/H2 */
  margin-top: 50px;
  margin-bottom: 48px;

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 40px;
    margin-bottom: 24px;
    /**/
  }
  color: #292838;
`;

const HeaderType = styled.div`
  /* Desktop/Heading/H2 */
  margin-top: 100px;
  margin-bottom: 48px;

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 40px;
    margin-top: 72px;
    margin-bottom: 24px;
    /**/
  }
  color: #292838;
`;

const Directions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const DirectionWrapper = styled(Direction)`
  //flex-basis: auto;
  //padding: 10px;
  margin: 5px 5px;
  //width: 350px;
  height: 276px;
  @media screen and (max-width: 1024px) {
    margin: 4px 1px;

    /**/
  }
`;
const DirectionsType = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //max-width: 100vw;
`;
const DirectionTypeWrapper = styled(DirectionType)`
  //margin: 10px;
`;

const DirectionsComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
`;

type DirectionsComponentType = {
  className?: string;
  id?: string;
  directions: DirectionDataT[];
  directionsType: DirectionTypeDataT[];
};

/*
const ChildComponent = React.forwardRef<Text>(
  (
    { title }: ChildComponentProps,
    ref: React.ForwardedRef<Text>,
  ): JSX.Element => {
    return <a ref={ref}></a>;
  },
);*/
/*
const AddLink = React.forwardRef<HTMLAnchorElement, DirectionDataT>(
  (props, ref, { href }) => {
    return (
      <a href={href} ref={ref} type="search">
        {props}
      </a>
    );
  },
);
AddLink.displayName = 'ChildComponent';

*/

const DirectionsComponent: NextPage<DirectionsComponentType> = ({
  directions,
  directionsType,
  className,
  id,
}): JSX.Element => {
  return (
    <DirectionsComponentWrapper
      className={className}
      id={id ? id : 'directions'}
    >
      <Header>Направления</Header>

      <Directions>
        {directions.map((c) => (
          <Link href={`/directions/${c.reflink}`} key={c.id} passHref>
            <a>
              <DirectionWrapper
                key={c.id}
                id={c.id}
                image={c.image}
                name={c.name}
              />
            </a>
          </Link>
        ))}
      </Directions>
      <HeaderType>Типы Занятий</HeaderType>
      <DirectionsType>
        {directionsType.map((c) => (
          <DirectionTypeWrapper
            key={c.id}
            id={c.id}
            name={c.name}
            description={c.description}
          />
        ))}
      </DirectionsType>
    </DirectionsComponentWrapper>
  );
};

export default DirectionsComponent;
