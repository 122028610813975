import { NextApiRequest, NextApiResponse } from 'next';

export interface DirectionDataI {
  id: number;
  name: string;
  description: string;
}
export const DirectionsTypeData: DirectionDataI[] = [
  {
    id: 1,
    name: 'Групповые',
    description:
      'Мы собираем небольшие группы, чтобы уделять внимание каждому ученику.',
  },
  {
    id: 2,
    name: 'Индивидуальные',
    description:
      'У нас есть опция индивидуальных занятий, для тех, кто хочет получить максимум от тренировок',
  },
];

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse,
): void {
  res.status(200).json(DirectionsTypeData);
}
