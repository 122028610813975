import { NextPage } from 'next';
import React, { useState } from 'react';
import { useFormspark } from '@formspark/use-formspark';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import ButtonComponent from 'components/atoms/buttons/Sign';
import ModalWindow from 'components/atoms/modals/Success';
import { type } from 'os';
import { sub } from 'date-fns';

const FORMSPARK_FORM_ID = 'nyGSw6q0';
//https://submit-form.com/OKiPOJoU

const Header = styled.div`
  /* Desktop/Heading/H2 */

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #292838;
  margin-top: 5rem;
  margin-bottom: 14px;
  @media screen and (max-width: 1024px) {
    /* Mobile/Heading/H2 */

    font-family: Cormorant;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;

    color: #292838;
  }
`;

const HeaderDescription = styled.div`
  /* Desktop/Body/Large */
  margin-bottom: 24px;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 170%;
  /* identical to box height, or 34px */

  color: #292838;
  @media screen and (max-width: 1024px) {
    /* Mobile/Heading/H2 */
    /* Mobile/Body/Medium */

    font-family: Jost;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;
    /* identical to box height, or 29px */

    color: #292838;
  }
`;

const FormElementsWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactFormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

type Submited = {
  submited: boolean;
};

const Submited: NextPage<Submited> = ({ submited }) => {
  if (submited) {
    return <div></div>;
  } else {
    return <div>форма отправлена</div>;
  }
};

const Input = styled.input`
  margin-bottom: 0.3rem;
  width: 317px;
  border-radius: 10px;
  padding: 11px 11px 11px 15px;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.purple};
  background-color: ${(props) => props.theme.colors.background};
  &:focus {
    background: white;
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 1.5rem;
  width: 317px;
  border: 0;
  outline: 0;
  background-color: ${(props) => props.theme.colors.background};

  border-radius: 10px;
  padding: 10px 11px 31px 15px;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${(props) => props.theme.colors.purple};
  &:focus {
    background: white;
  }
`;

type ContactFormT = {
  className?: string;
  id?: string;
};

const ContactForm: NextPage<ContactFormT> = ({ className, id }) => {
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //const onSubmit = (data) => console.log(data);
  //console.log(errors);

  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault;
    await submit({ message, phone, name });
    setFormSuccess(true);
  };

  return (
    <ContactFormElementWrapper id={id ? id : 'contact'} className={className}>
      <Header>Записаться на урок</Header>
      <HeaderDescription>
        Оставь заявку и мы с тобой свяжемся!
      </HeaderDescription>
      <FormElementsWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="text"
          onChange={(e) => setMessage(e.target.value)}
          onInput={(e: React.FormEvent<HTMLInputElement>) => {
            const nameInput = e.currentTarget.value;
            setName(nameInput);
          }}
          placeholder="Имя"
          {...register('name', {
            required: true,
            max: 25,
            min: 3,
            maxLength: 25,
          })}
        />

        {/*errors.name && <div>Too long </div>*/}
        <Input
          type="tel"
          onInput={(e: React.FormEvent<HTMLInputElement>) => {
            const phoneInput = e.currentTarget.value;
            setPhone(phoneInput);
          }}
          value={phone}
          placeholder="Телефон"
          {...register('Телефон', { required: true, maxLength: 12 })}
        />
        <TextArea
          //onChange={(e) => setName(e.target.value)}
          onInput={(e: React.FormEvent<HTMLTextAreaElement>) => {
            const messageInput = e.currentTarget.value;
            setMessage(messageInput);
          }}
          value={message}
          placeholder="Текст сообщения (Необязательно)"
          {...register('Текст сообщения (Необязательно)', {
            max: 100,
            min: 1,
            //maxLength: 100,
          })}
        />
        <ButtonComponent text={'Записаться'}></ButtonComponent>
        <ModalWindow
          setActive={setFormSuccess}
          active={formSuccess}
        ></ModalWindow>
      </FormElementsWrapper>
    </ContactFormElementWrapper>
  );
};
/*
        <Submited submited={formSuccess}></Submited>

        <input
          type="text"
          id="name"
          name="name"
          placeholder="Имя"
          required={true}
        />
        <input
          type="phone"
          id="phone"
          name="phone"
          placeholder="Телефон"
          required={true}
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button type="submit" disabled={submitting}>
          Send
        </button>

    
          <form action="">

        <textarea
          id="message"
          name="message"
          placeholder="Текст сообщения (Необязательно)"
          required={false}
        ></textarea>
        <button type="submit">Send</button>
      </form>
    */

export default ContactForm;
