import { NextPage } from 'next';
import styled from 'styled-components';

export type DirectionTypeDataT = {
  className?: string;
  id?: number;
  name?: string;
  description?: string;
};

const Name = styled.div`
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  color: #292838;
`;

const Description = styled.div`
  /* Desktop/Body/Large */

  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 170%;
  /* or 34px */
  max-width: 400px;

  color: #292838;
`;
const DirectionTypeComponentWrapper = styled.div`
  padding: 20px;
`;

const DirectionTypeComponent: NextPage<DirectionTypeDataT> = ({
  className,
  name,
  description,
}) => {
  return (
    <DirectionTypeComponentWrapper className={className}>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </DirectionTypeComponentWrapper>
  );
};

export default DirectionTypeComponent;
