import { NextApiRequest, NextApiResponse } from 'next';
import { DirectionDataT } from 'components/molecules/Direction';
/*
export interface DirectionDataI {
  id: number;
  name: string;
  image: string;
}*/
export const DirectionsData: DirectionDataT[] = [
  {
    id: 1,
    name: 'Балет',
    reflink: 'ballet',
    image: '/assets/directions/ballet/ballet.avif',
  },
  {
    id: 2,
    name: 'Стретчинг',
    reflink: 'stretching',
    image: '/assets/directions/stretching/stretching.avif',
  },
  {
    id: 3,
    name: 'Тренаж',
    reflink: 'training',
    image: '/assets/directions/training/training.avif',
  },
];

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse,
): void {
  res.status(200).json(DirectionsData);
}
