//import useCalendar from '@veccu/react-calendar';
import React from 'react';
import { format, addDays, startOfWeek } from 'date-fns';
import locale from 'date-fns/locale/ru';
import { NextPage } from 'next';
import styled from 'styled-components';
import { useFlexLayout, useTable } from 'react-table';
import SlotComponent from 'components/molecules/Slot';
export type StructureT =
  | {
      id: number;
      dayId: number[];
      startTime: string;
      endTime: string;
      type: string;
      location: string[];
      locationIcon: string;
    }
  | undefined;
const weekIds = [1, 2, 3, 4, 5, 6, 7];
export const StructuredList: StructureT[] = [
  {
    id: 1,
    dayId: [1, 3, 5],
    startTime: '8:30',
    endTime: '10:00',
    type: 'Балет STEP1 + стретчинг',
    location: ['Кловская'],
    locationIcon: '/assets/icons/MetroGreen.svg',
  },
  {
    id: 2,
    dayId: [1],
    startTime: '09:00',
    endTime: '10:00',
    type: 'Балет STEP1',
    location: ['Оболонь'],
    locationIcon: '/assets/icons/MetroBlue.svg',
  },
  {
    id: 3,
    dayId: [6],
    startTime: '09:30',
    endTime: '10:30',
    type: 'Тренаж + стретчинг',
    location: ['Борщаговка'],
    locationIcon: '/assets/icons/Location.svg',
  },
  {
    id: 4,
    dayId: [1],
    startTime: '10:00',
    endTime: '11:00',
    type: 'Стретчинг',
    location: ['Оболонь'],
    locationIcon: '/assets/icons/MetroBlue.svg',
  },
  {
    id: 5,
    dayId: [6],
    startTime: '11:30',
    endTime: '12:30',
    type: 'Балет STEP1',
    location: ['Оболонь'],
    locationIcon: '/assets/icons/MetroBlue.svg',
  },
  {
    id: 6,
    dayId: [6],
    startTime: '12:30',
    endTime: '13:30',
    type: 'Стретчинг',
    location: ['Оболонь'],
    locationIcon: '/assets/icons/MetroBlue.svg',
  },
  {
    id: 7,
    dayId: [1, 3, 5],
    startTime: '17:30',
    endTime: '18:30',
    type: 'Балет STEP2',
    location: ['Кловская'],
    locationIcon: '/assets/icons/MetroGreen.svg',
  },
  {
    id: 8,
    dayId: [2, 4],
    startTime: '18:30',
    endTime: '19:30',
    type: 'Тренаж',
    location: ['Кловская'],
    locationIcon: '/assets/icons/MetroGreen.svg',
  },
  {
    id: 9,
    dayId: [1, 3, 5],
    startTime: '19:00',
    endTime: '20:00',
    type: 'Балет STEP1',
    location: ['Кловская'],
    locationIcon: '/assets/icons/MetroGreen.svg',
  },
  {
    id: 10,
    dayId: [3],
    startTime: '19:00',
    endTime: '20:00',
    type: 'Балет STEP1',
    location: ['Оболонь'],
    locationIcon: '/assets/icons/MetroBlue.svg',
  },
  {
    id: 11,
    dayId: [1, 3, 5],
    startTime: '19:00',
    endTime: '20:00',
    type: 'Балет STEP1',
    location: ['Левобережная'],
    locationIcon: '/assets/icons/MetroRed.svg',
  },
  {
    id: 12,
    dayId: [2, 4],
    startTime: '19:30',
    endTime: '21:00',
    type: 'Балет STEP3',
    location: ['Кловская'],
    locationIcon: '/assets/icons/MetroGreen.svg',
  },
  {
    id: 13,
    dayId: [1, 3, 5],
    startTime: '20:00',
    endTime: '21:00',
    type: 'Стретчинг',
    location: ['Кловская'],
    locationIcon: '/assets/icons/MetroGreen.svg',
  },
  {
    id: 14,
    dayId: [3],
    startTime: '20:00',
    endTime: '21:00',
    type: 'Стретчинг',
    location: ['Борщаговка'],
    locationIcon: '/assets/icons/Location.svg',
  },

  {
    id: 15,
    dayId: [1, 3, 5],
    startTime: '20:00',
    endTime: '21:00',
    type: 'Стретчинг',
    location: ['Левобережная'],
    locationIcon: '/assets/icons/MetroRed.svg',
  },
];

const Head = styled.div`
  /* Desktop/Heading/H2 */

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 40px;

  color: #292838;
  @media screen and (max-width: 1024px) {
    margin-bottom: 24px;
  }
`;
const PriceComponentWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Styles = styled.div`
  max-width: 95vw;
  overflow-x: auto;

  table {
    width: max-content;
    border-spacing: 0;
    border: 1px solid #917c93;
    thead {
      background-color: #f1edef;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      /* Desktop/Body/Medium */

      font-family: Jost;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      color: #292838;
    }
    td {
      /* Desktop/Heading/H4 */

      font-family: Jost;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 135%;
      /* identical to box height, or 22px */

      color: #292838;
    }
    th,
    td {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 70px;
      padding: 0.5rem;
      border-bottom: 1px solid #917c93;
      border-right: 1px solid #917c93;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data }) {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      //minWidth: 250, // minWidth is only used as a limit for resizing
      width: 205, // width is used for both the flex-basis and flex-grow
      maxWidth: 205, // maxWidth is only used as a limit for resizing
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
    );
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return (
                  <td key={i} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
type PriceT = {
  className?: string;
};

const ClaendarTableComponent: NextPage<PriceT> = ({ className }) => {
  const firstDOW = startOfWeek(new Date());
  const columns = React.useMemo(
    () => [
      {
        Header: format(addDays(firstDOW, 1), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: format(addDays(firstDOW, 2), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col2',
      },
      {
        Header: format(addDays(firstDOW, 3), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col3',
      },
      {
        Header: format(addDays(firstDOW, 4), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col4',
      },
      {
        Header: format(addDays(firstDOW, 5), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col5',
      },
      {
        Header: format(addDays(firstDOW, 6), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col6',
      },
      {
        Header: format(addDays(firstDOW, 7), 'EEEE', {
          locale,
          weekStartsOn: 1,
        }),
        accessor: 'col7',
      },
    ],

    [firstDOW],
  );
  const preparedToRender: { [k: number]: StructureT[] } = {};

  weekIds.forEach((dayId) => {
    preparedToRender[dayId] = [];
  });
  StructuredList.map((item, id) => {
    weekIds.forEach((dayId) => {
      if (item) {
        if (item.dayId.indexOf(dayId) !== -1) {
          preparedToRender[dayId].push(item);
        } else {
          preparedToRender[dayId].push(undefined);
        }
      }
    });
  });
  const tableRender: { [k: number]: StructureT[] } = {};
  Object.entries(preparedToRender).map(([, dayDataArray]) => {
    dayDataArray.map((dayData, index) => {
      if (tableRender[index] === undefined) {
        tableRender[index] = [];
      }

      if (dayData) {
        tableRender[index].push(dayData);
      } else if (dayData === undefined) {
        tableRender[index].push(undefined);
      }
    });
  });
  type DaysTable = {
    [key: string]: JSX.Element;
  };
  const renderDaysTable: DaysTable[] = [];
  {
    // here we have array of rows
    Object.entries(tableRender).map(([, d]) => {
      // here we have 1 row
      const dayCell: DaysTable = {};
      d.map((data, id) => {
        // here we have events {} or undefined
        // список обьектов с {col1: <div></div>, col2: <div></div>}
        if (data) {
          dayCell['col' + (id + 1)] = (
            <SlotComponent
              id={id}
              dayId={data.dayId}
              startTime={data.startTime}
              endTime={data.endTime}
              type={data.type}
              location={data.location}
              locationIconUrl={data.locationIcon}
            ></SlotComponent>
          );
        } else {
          dayCell['col' + (id + 1)] = <div></div>;
        }
        //
      });
      renderDaysTable.push(dayCell);
    });
  }
  return (
    <PriceComponentWrapper className={className} id="schedule">
      <Head>Расписание</Head>
      <Styles>
        <Table columns={columns} data={renderDaysTable} />
      </Styles>
    </PriceComponentWrapper>
  );
};

export default ClaendarTableComponent;
