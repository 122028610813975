import ButtonComponent from 'components/atoms/buttons/Sign';
import styled from 'styled-components';
//import Image from 'next/image';
import { NextPage } from 'next';
import ImgCool from 'react-cool-img';

export const HeroComonent = styled.div`
  display: flex;
  justify-content: stretch;
  min-height: 800px;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

export const ButtonComponentWrapper = styled(ButtonComponent)`
  margin-top: 48px;
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //text-align: center;
  //justify-content: flex-end;

  @media screen and (max-width: 1024px) {
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LeftSideTextBlock = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const LeftSideTextBlockHeading = styled.div`
  //width: 100%;
  color: #292838;
  font-size: ${(props) => props.theme.fonts.heading.size};
  font-family: ${(props) => props.theme.fonts.heading.family};
  font-weight: ${(props) => props.theme.fonts.heading.weight};
  line-height: ${(props) => props.theme.fonts.heading.lineHeight};
  @media screen and (max-width: 1024px) {
    font-size: 35px;
    /**/
  }
`;
export const LeftSideTextBlockDescription = styled.div`
  width: 70%;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 170%;
  color: #292838;
`;

export const ImageWrapper = styled.div`
  position: relative;
  max-width: 508px;
  //height: 611px;
  //left: 730px;
  //top: 87px;
`;
const Img = styled.img`
  //max-width: 100%;
  object-fit: cover;

  //max-height: 100%;
  height: 100%;
  width: 100%;
`;

type Hero = {
  id?: string;
  className?: string;
};

const index: NextPage<Hero> = ({ className, id }): JSX.Element => {
  return (
    <HeroComonent className={className} id={id ? id : 'hero'}>
      <LeftSide>
        <LeftSideTextBlock>
          <LeftSideTextBlockHeading>
            {'Shamry art space'.toUpperCase()}
          </LeftSideTextBlockHeading>
          <LeftSideTextBlockDescription>
            Студия балета для взрослых. Научим балету начинающих и с опытом в
            любом возрасте и с разной физической подготовкой.
          </LeftSideTextBlockDescription>
          <a href="/#contact" style={{ textDecoration: 'none' }}>
            <ButtonComponentWrapper text="Записаться на урок"></ButtonComponentWrapper>
          </a>
        </LeftSideTextBlock>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img
            //width="508px"
            //height="611px"
            alt="ballet stretching training"
            src="/assets/herophoto.avif"
          ></Img>
        </ImageWrapper>
      </RightSide>
    </HeroComonent>
  );
};

export default index;
