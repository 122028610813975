import { InferGetStaticPropsType } from 'next';
import React from 'react';
import styled from 'styled-components';
import Directions from 'components/organisms/Directions';

import Faq, { FaqDataT } from 'components/organisms/Faq';
import Choose, { AdvatageDataI } from 'components/organisms/Choose';
import Important, { ImportantDataT } from 'components/organisms/Important';
//import Schedule from 'components/organisms/Schedule';
import TableCalendar from 'components/organisms/TableCalendar';
import { DirectionDataT } from 'components/molecules/Direction';
import { DirectionTypeDataT } from 'components/molecules/DirectionType';
import Hero from 'components/organisms/Hero';

//import Calendar from 'components/organisms/Calendar';
//import Clnd from 'components/organisms/HeadlessCalendar';
import Price from 'components/organisms/Price';
import { LocationDataT } from 'components/molecules/Location';
import { AdvatageData } from 'pages/api/advantage';
import { DirectionsData } from 'pages/api/directions';
import { DirectionsTypeData } from 'pages/api/directionstype';
import { FaqData } from 'pages/api/faq';
import { LocationData } from 'pages/api/locations';
import ContactForm from 'components/organisms/ContactForm';
import { ImportantData } from 'pages/api/important';
// Contexts

const HeaderWrapper = styled.div`
  grid-area: 1 / 2 / 2 / 3;

  margin-bottom: 40px;
  //background-color: blanchedalmond;
`;

const ContactBackground = styled.div`
  grid-area: 3 / 1 / 4 / 4;
  background-color: #f1edef;
`;

const ContactWrapper = styled.div`
  grid-area: 3 / 2 / 4 / 3;
`;

const HeaderBackground = styled.div`
  grid-area: 1 / 1 / 2 / 4;
  background-color: #f1edef;

  margin-bottom: 40px;
  //background-color: blanchedalmond;
`;

const Content = styled.div`
  grid-area: 2 / 2 / 3 / 3;
`;

const DirectionsWrapper = styled(Directions)``;

const PriceWrapper = styled(Price)`
  margin-top: 79px;
  margin-bottom: 20px;
`;

const ImportantWrapper = styled(Important)`
  margin-top: 20px;
  padding: 1rem;
`;

const FaqWrapper = styled(Faq)`
  margin-top: 100px;
`;

const TableCalendarWrapper = styled(TableCalendar)`
  margin-top: 100px;
  @media screen and (max-width: 1024px) {
    margin-top: 78px;
  }
`;

/*
const Home = ({
  advantages,
  directions,
  directionsType,
  important,
  faq,
  locations,
}: InferGetStaticPropsType<typeof getStaticProps>): JSX.Element => {*/

const HomeComponentWrapper = styled.div`
  justify-content: center;
  display: grid;
  background-color: white;

  //align-items: center;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 20px auto 20px;
  //minmax(auto, 1440px)
  @media screen and (max-width: 1024px) {
    grid-template-columns: 10px auto 10px;
  }
`;

const Home = (): JSX.Element => {
  //console.log(advantages);
  //console.log(directions);
  //console.log(important);
  return (
    <HomeComponentWrapper>
      {/*<main></main>*/}
      <HeaderBackground></HeaderBackground>
      <HeaderWrapper>
        <Hero />
      </HeaderWrapper>

      <Content>
        <Choose advantages={AdvatageData} />
        <DirectionsWrapper
          directions={DirectionsData}
          directionsType={DirectionsTypeData}
        />
        <PriceWrapper />
        <ImportantWrapper></ImportantWrapper>
        <TableCalendarWrapper></TableCalendarWrapper>
        <FaqWrapper data={FaqData}></FaqWrapper>
      </Content>
      <ContactBackground />
      <ContactWrapper>
        <ContactForm></ContactForm>
      </ContactWrapper>
    </HomeComponentWrapper>
  );
};
{
  /*<Clnd></Clnd>*/
}

{
  /*<Calendar></Calendar>*/
}

export default Home;

//import advantages from 'pages/api/advantage';
/*export const getStaticProps = async () => {
  // Call an external API endpoint to get posts.
  // You can use any data fetching library
  //const res = await fetch('https://.../posts');
  const reqAdvantages = await fetch(`http://127.0.0.1:3000/api/advantage`);
  const reqDirections = await fetch(`http://127.0.0.1:3000/api/directions`);
  const reqDirectionsType = await fetch(
    `http://127.0.0.1:3000/api/directionstype`,
  );
  const reqImportant = await fetch(`http://127.0.0.1:3000/api/important`);
  const reqFaq = await fetch(`http://127.0.0.1:3000/api/faq`);
  const reqLocations = await fetch(`http://127.0.0.1:3000/api/locations`);

  //const req = await advantages();
  const advantages: AdvatageDataI[] = await reqAdvantages.json();
  const directions: DirectionDataT[] = await reqDirections.json();
  const directionsType: DirectionTypeDataT[] = await reqDirectionsType.json();
  const important: ImportantDataT[] = await reqImportant.json();
  const faq: FaqDataT = await reqFaq.json();
  const locations: LocationDataT[] = await reqLocations.json();

  // By returning { props: { posts } }, the Blog component
  // will receive `posts` as a prop at build time
  return {
    props: {
      important,
      advantages,
      directions,
      directionsType,
      faq,
      locations,
    },
  };
};
*/
