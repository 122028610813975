import { NextPage } from 'next';
//import Image from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';

export type DirectionDataT = {
  id?: number;
  reflink?: string;
  className?: string;
  name?: string;
  image?: string;
};

const Direction = styled.div`
  position: relative;
  background-color: #917c93;

  //border: 2px dashed #444;
  //float: left;
  //width: 100%;
`;

const ImageWrapper = styled.div`
  // display: block;
  position: relative;
  opacity: 0.5;
  //width: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  //filter: hue-rotate(-40deg);
`;
const Img = styled.img`
  //max-width: 100%;
  object-fit: cover;

  //max-height: 100%;
  height: 100%;
  width: 100%;
  //height: auto;
  //width: auto;
  //position: absolute;
`;

const Elements = styled.div`
  display: flex;
  //text-align: center;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  //align-content: center;
  width: 85%;

  bottom: 15px;
  z-index: 1;
  margin-left: 25px;
  //margin: 0 24px 0 24px;
`;

const Name = styled.div`
  font-family: Jost;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #ffffff;
`;

const DirectionComponent: NextPage<DirectionDataT> = ({
  className,
  name,
  image,
}): JSX.Element => {
  return (
    <Direction className={className}>
      <ImageWrapper>
        <Img
          //layout="fill"
          //width="403px"

          //height="276px"
          //objectPosition="center"
          //objectFit="cover"
          alt="direction item"
          src={image ? image : '/assets/directions/ballet/ballet.png'}
        ></Img>
      </ImageWrapper>
      <Elements>
        <Name>{name ? name : 'DIRECTION'}</Name>
        <img
          src="/assets/icons/ArrowRightT.svg"
          height="28"
          width="28"
          alt="arrow"
        ></img>
      </Elements>
    </Direction>
  );
};

export default DirectionComponent;
