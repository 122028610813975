import { NextPage } from 'next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Icon from 'components/atoms/icon';
import styled, { keyframes } from 'styled-components';

export type QuestionAndAnswerDataT = {
  id: number;
  question: string;
  answer: string;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const AccordionWrapper = styled(Accordion)``;

export const AccordionItemWrapper = styled(AccordionItem)``;

const OpenIconAtom = () => {
  return <Icon src="/assets/icons/ArrowRight.svg"></Icon>;
};
const CloseIconAtom = () => {
  return <Icon src="/assets/icons/ArrowDown.svg"></Icon>;
};

export const OpenIcon = styled(OpenIconAtom)``;

export const CloseIcon = styled(CloseIconAtom)`
  opacity: 0;
`;

export const AccordionItemHeadingWrapper = styled(AccordionItemHeading)``;

export const AccordionItemButtonWrapper = styled(AccordionItemButton)`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  //padding: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  &:hover {
    background-color: #f4f4f4;
  }
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  color: #292838;
`;

export const AccordionItemPanelWrapper = styled(AccordionItemPanel)`
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  animation: 0.35s ${fadeIn} ease-in;
  &.accordion__body--hidden {
    animation: 0.35s ${fadeIn} ease-in;
  }
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 30px */

  color: #292838;
`;
type QuestionAndAnswerComponentT = {
  className?: string;
  data: QuestionAndAnswerDataT[];
};
const QuestionAndAnswerComponent: NextPage<QuestionAndAnswerComponentT> = ({
  data,

  className,
}) => {
  return (
    <AccordionWrapper className={className}>
      <Accordion allowZeroExpanded>
        {data.map((c) => (
          <AccordionItemWrapper key={c.id} uuid={c.id.toString()}>
            <AccordionItemHeadingWrapper>
              <AccordionItemButtonWrapper>
                <div>{c.question}</div>
                <CloseIcon></CloseIcon>
              </AccordionItemButtonWrapper>
            </AccordionItemHeadingWrapper>
            <AccordionItemPanelWrapper>{c.answer}</AccordionItemPanelWrapper>
          </AccordionItemWrapper>
        ))}
      </Accordion>
    </AccordionWrapper>
  );
};

export default QuestionAndAnswerComponent;
