import styled from 'styled-components';
import { NextPage } from 'next';
import { ButtonHTMLAttributes, ReactNode } from 'react';

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.purple};
  border: 0;
  outline: 0;
  border-radius: 60px;
  padding: 0px 0px;
  height: 56px;
  width: 276px;
  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
export const ZapisatSjaNaUrok = styled.p`
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  letter-spacing: 0.48px;
  font-family: ${(props) => props.theme.fonts.desktopBodyButton.family};
  font-size: ${(props) => props.theme.fonts.desktopBodyButton.size};
  font-weight: ${(props) => props.theme.fonts.desktopBodyButton.weight};
  line-height: ${(props) => props.theme.fonts.desktopBodyButton.lineHeight};
`;

type IButtonProps = {
  children: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonI = {
  className?: string;
  text: string;
  buttonProps?: IButtonProps;
};
const ButtonComponent: NextPage<ButtonI> = ({
  buttonProps,
  text,
  className,
}): JSX.Element => {
  const { ...rest } = buttonProps;
  return (
    <Button {...rest} className={className}>
      <ZapisatSjaNaUrok>{text}</ZapisatSjaNaUrok>
    </Button>
  );
};

export default ButtonComponent;
