import { NextApiRequest, NextApiResponse } from 'next';
import { FaqDataT } from 'components/organisms/Faq';

export const FaqData: FaqDataT = {
  header: 'FAQ',
  imageUrl: '/assets/faqimage.png',
  qa: [
    {
      id: 1,
      question: 'Когда я сяду на шпагат и стану гибким?',
      answer:
        'Все зависит от ваших индивидуальных особенностей. У всех людей разная эластичность мышц и подвижность суставов. Чем лучше эти факторы, тем легче и быстрее вы растянитесь. Результат также зависит от систематичности посещений занятий.',
    },
    {
      id: 2,
      question: 'Что надеть на балет?',
      answer:
        'На первый раз подойдет любая обтягивающая сменная одежда, балетки или носочки! В дальнейшем : купальник для танцев , трико( колготки) и хитон( юбка). Волосы должны быть аккуратно собраны.',
    },
    {
      id: 3,
      question: 'С чего начать если есть лишний вес?',
      answer:
        'Если у вас есть лишний вес, мы советуем начать вам свой танцевальный путь с занятий по тренажу. Именно он поможет безопасно убрать лишний вес и похудеть. Начав свои занятия с тренажа вы в скором времени сможете чувствовать себя безопасно на балете и растяжке.',
    },
    {
      id: 4,
      question: 'Есть ли ограничения по возрасту или отдельные группы?',
      answer:
        'Абсолютно все занятия в студии для людей с 18 до 100😊. На наших занятиях есть люди которым и 20 , 35 и 60. Каждый может найти то направление  которое больше будет подходить именно ему. Индивидуальные занятия проводим  и взрослым и детям любого возраста.',
    },
  ],
};
export default function handler(
  req: NextApiRequest,
  res: NextApiResponse,
): void {
  res.status(200).json(FaqData);
}
