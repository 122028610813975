import { NextPage } from 'next';
import styled from 'styled-components';
import {
  useTable,
  useFlexLayout,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';

import React from 'react';

type PriceT = {
  id?: string;
  className?: string;
};

const PriceComponentWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeadWrapper = styled.div`
  margin-bottom: 48px;
  /* Desktop/Heading/H2 */

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #292838;

  @media screen and (max-width: 1024px) {
    margin-bottom: 24px;
    /* Mobile/Heading/H2 */

    font-family: Cormorant;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;

    color: #292838;
    /**/
  }
`;

const Styles = styled.div`
  //max-width: fit-content;
  max-width: 95vw;
  //text-align: left;
  overflow-x: auto;

  table {
    width: max-content;
    border-spacing: 0;
    border: 1px solid #917c93;
    thead {
      background-color: #f1edef;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      /* Desktop/Body/Medium */

      font-family: Jost;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      color: #292838;
      //max-width: -moz-fit-content;
      //max-width: fit-content;
      //max-width: 100vh;
      //white-space: pre-wrap;
    }
    td {
      /* Desktop/Heading/H4 */

      font-family: Jost;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 135%;
      /* identical to box height, or 22px */

      color: #292838;
    }
    th,
    td {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      padding: 0.5rem;
      border-bottom: 1px solid #917c93;
      border-right: 1px solid #917c93;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data }) {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 287, // minWidth is only used as a limit for resizing
      //width: 200.5, // width is used for both the flex-basis and flex-grow
      //maxWidth: 400, // maxWidth is only used as a limit for resizing
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      //useFlexLayout,

      //useResizeColumns,
      //useBlockLayout,
    );
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return (
                  <td key={i} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
//export default Table;

const PriceComponent: NextPage<PriceT> = ({ className, id }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Вид абонемента',
        accessor: 'col1', // accessor is the "key" in the data
      },

      {
        Header: 'Групповые 1 час',
        accessor: 'col2',
      },
      {
        Header: 'Групповые 1,5 часа',
        accessor: 'col3',
      },
      {
        Header: 'Индивидуальные в зале',
        accessor: 'col4',
      },
      {
        Header: 'Индивидуальные онлайн',
        accessor: 'col5',
      },
    ],

    [],
  );
  const data = React.useMemo(
    () => [
      {
        col1: 'Разовое занятие',
        col2: '250 грн.',
        col3: '370 грн.',
        col4: '600 грн.',
        col5: '300 грн.',
      },

      {
        col1: '4 занятия',
        col2: '900 грн.', //Групповые 1 час
        col3: '-', //'Групповые 1,5 часа',
        col4: '-', //'Индивидуальные в зале',
        col5: '-', //'Индивидуальные онлайн',
      },
      {
        col1: '6 занятий',
        col2: '-',
        col3: '1900 грн.',
        col4: '-',
        col5: '-',
      },
      {
        col1: '8 занятий',
        col2: '1700 грн.',
        col3: '2500 грн.',
        col4: '4400 грн.',
        col5: '2000 грн.',
      },
      {
        col1: '12 занятий',
        col2: '2500 грн.',
        col3: '3500 грн.',
        col4: '6000 грн.',
        col5: '-',
      },
      {
        col1: '16 занятий',
        col2: '3200 грн.',
        col3: '-',
        col4: '-',
        col5: '-',
      },
    ],

    [],
  );

  return (
    <PriceComponentWrapper className={className} id={id ? id : 'price'}>
      <HeadWrapper>Цены</HeadWrapper>
      <Styles>
        <Table columns={columns} data={data} />
      </Styles>
    </PriceComponentWrapper>
  );
};

export default PriceComponent;
