import { NextPage } from 'next';
//import Image from 'next/image';
import styled from 'styled-components';
import QuestionAndAnswer, {
  QuestionAndAnswerDataT,
} from 'components/molecules/QuestionAndAnswer';

export type FaqDataT = {
  header: string;
  imageUrl: string;
  qa: QuestionAndAnswerDataT[];
};

type FaqComponentT = {
  data: FaqDataT;
  className?: string;
  id?: string;
};

const FaqComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  flex-wrap: wrap;
  @media screen and (max-width: 800px) {
    margin-bottom: 21px;
  }
  margin-bottom: 98px;
`;
const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  margin-left: 5%;
  margin-right: 5%;
  //background-color: blanchedalmond;
`;
const Img = styled.img`
  //max-width: 100%;
  object-fit: cover;

  //max-height: 100%;
  height: 100%;
  width: 100%;
`;
const ImageWrapper = styled.div``;
const QuestionsAndAnswers = styled.div`
  //  width: 100%;
`;
const Header = styled.div`
  /* Desktop/Heading/H2 */

  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #292838;
`;

const FaqComponent: NextPage<FaqComponentT> = ({ data, className, id }) => {
  return (
    <FaqComponentWrapper className={className}>
      <ImageWrapper
      // width="508px"
      //height="611px"
      >
        <Img src="/assets/faqimage.avif" alt="faq"></Img>
      </ImageWrapper>
      <TextWrapper>
        <Header>FAQ</Header>
        <QuestionsAndAnswers>
          <QuestionAndAnswer data={data.qa}></QuestionAndAnswer>
        </QuestionsAndAnswers>
      </TextWrapper>
    </FaqComponentWrapper>
  );
};

export default FaqComponent;
